import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Industries from "../layouts/Industries"
import {graphql, useStaticQuery} from 'gatsby';

const IndustriesPageFr = () => {
  const data = useStaticQuery(graphql`
    query IndustriesHeaderImageFr {
      image: file(relativePath: { eq: "industries/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulSecteurs(filter: {node_locale: {eq: "fr-CA"}}, sort: {fields: createdAt}) {
        edges {
          node {
            name
            permalink
            contentful_id
            image {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    lang="fr-CA" 
    switchLink='/en/industries' 
    slider={true} 
    headerImage={data.image.childImageSharp} 
    title="Un outil de communication indispensable et adapté à votre secteur d'activité" 
    subTitle="Que ce soit avec vos employés ou clients, nous avons la solution à vos enjeux de communication." anchorLink="#industries">
      <Seo 
        title="Solutions d'affichage dynamique adaptées à vos activités"
        description="Peu importe votre secteur d'activités et votre auditoire, vous allez sauver du temps en utilisant nos gabarits infographiés et animés que vous pourrez personnaliser et ajuster à votre image."
       />
      <Industries lang="fr-CA" industries={data.allContentfulSecteurs.edges}/>
    </Layout>
  )
}

export default IndustriesPageFr